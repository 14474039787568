var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('p',{staticClass:"library__subtitle"},[_vm._v("Design and build coherent experiences with vadobay’s library.")]),_c('div',{staticClass:"library__wrap"},[_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('router-link',{staticClass:"library__router-link",attrs:{"to":{name: 'typography'}}},[_c('vb-card',{attrs:{"card":{
                title: 'Typography',
                text: 'Everything you need to present content as clearly and efficiently as possible.',
              }}})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('router-link',{staticClass:"library__router-link",attrs:{"to":{name: 'components'}}},[_c('vb-card',{attrs:{"card":{
                title: 'Components',
                text: 'Presentational UI components with encapsulated styles for VUE.',
              }}})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('router-link',{staticClass:"library__router-link",attrs:{"to":{name: 'icons'}}},[_c('vb-card',{attrs:{"card":{
                title: 'Icons',
                text: 'Communicate messages at a glance and draw attention to important information.',
              }}})],1)],1)],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"library__header"},[_c('img',{staticClass:"library__logo",attrs:{"src":"/assets/images/common/brands/live-booking.svg","alt":"vadobay library"}}),_c('h1',{staticClass:"library__title"},[_vm._v("VB DESIGN SYSTEM")])])
}]

export { render, staticRenderFns }