<template>
  <div>
    <div class="library__header">
      <img class="library__logo" src="/assets/images/common/brands/live-booking.svg" alt="vadobay library" />
      <h1 class="library__title">VB DESIGN SYSTEM</h1>
    </div>
    <p class="library__subtitle">Design and build coherent experiences with vadobay’s library.</p>

    <div class="library__wrap">
      <b-row>
        <b-col cols="3">
          <router-link class="library__router-link" :to="{name: 'typography'}">
            <vb-card :card="{
                  title: 'Typography',
                  text: 'Everything you need to present content as clearly and efficiently as possible.',
                }" />
          </router-link>
        </b-col>
        <b-col cols="3">
          <router-link class="library__router-link" :to="{name: 'components'}">
            <vb-card :card="{
                  title: 'Components',
                  text: 'Presentational UI components with encapsulated styles for VUE.',
                }" />
          </router-link>
        </b-col>
        <b-col cols="3">
          <router-link class="library__router-link" :to="{name: 'icons'}">
            <vb-card :card="{
                  title: 'Icons',
                  text: 'Communicate messages at a glance and draw attention to important information.',
                }" />
          </router-link>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>

export default {
  name: 'homepage',

  components: {
  },

  props: {},

  data() {
    return {
    }
  },

  created() {
  },

  computed: {
  },

  mounted() {
  }
}
</script>

<style lang="scss">

</style>